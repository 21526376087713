import React, { useState, useEffect, useRef, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector, useDispatch } from "react-redux";
import {
  useGetCampusesMutation,
  useGetEmployerSuggestionsQuery,
  usePostAJobMutation,
  useUpdateJobMutation,
  useDeductJobCreditsMutation
} from "../store/apiSlice";
import JobKeywordSearchBlock from "./JobKeywordSearchBlock";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import InputBlock from "../components/ui/InputPostAJob";
import InputBlock2 from "./InputBlock2";
import SelectBlock2 from "./SelectBlock2";
import SelectBlock from "./SelectBlock";
import UniSearchBlock from "./UniSearchBlock";
import SelectBlockNew from "./SelectBlockNew";
import SelectBlock1 from "./SelectBlock1";
import job_category from "../data/JobCategories1.json";
import { countries } from "../utils/data";
import { currencies } from "../utils/data";
import { useNavigate, useLocation, Link, NavLink } from "react-router-dom";
//import { setemployer.jobCredits } from "./postSlice"
import { regions } from "../utils/data";
import { setJob, setId, setEmployer  } from '../store/postsSlice'
import {
  useSendEmail3Mutation
} from '../store/apiSlice'
import { BsCheckLg } from "react-icons/bs";
import ImageResize from 'quill-image-resize-module-react';
const validationSchema = yup.object({
  // username: yup.string().required('Missing username'),
  // email: yup.string().required('Missing email').email('Invalid email format'),
  //password: yup.string().required('Missing password'),
}).required()
const subjects = [
  "English",
  "Mathematics",
  "Science",
  "Special Needs",
  "Primary Education",
  "Skills for Life",
  "Education",
  "EAL/English as an Additional Language",
  "Physical Education",
  "Physics",
  "Early learning/Early childhood/Childcare",
  "Modern Languages",
  "Chemistry",
  "Art and Design",
  "Computer Science",
  "Music",
  "Biology",
  "Humanities",
  "Information Technology",
  "Other Subjects",
  "Geography",
  "History",
  "Business Studies",
  "Business Studies and Economics",
  "Drama",
  "Design and Technology",
  "English as a Foreign Language",
  "Construction and Engineering Trades",
  "Economics",
  "Food Technology/Hospitality and Catering",
  "Psychology",
  "Religious Education",
  "Performing Arts",
  "Learning Support",
  "Personal, Social, Health and Economic Education (PSHE)",
  "Social Sciences",
  "Child Development",
  "Vocational Studies",
  "Media Studies",
  "Generalist",
  "Pastoral/Curriculum Management",
  "Quran / Islamic Studies",
  "Citizenship",
  "Sociology",
  "Philosophy",
  "Accounting and Finance",
  "Careers",
  "Graphics",
  "Politics",
  "Classics",
  "Wellbeing",
  "Agriculture/Rural Science",
  "Animal Studies",
  "Environmental Studies",
  "Law",
  "Public Services",
  "Aboriginal and Torres Strait Islander Studies",
  "Aerospace Studies",
  "Modern Studies",
  "Social and Political Studies",
  "Teacher Training"
]


const academic_positions = {
  "Teaching and Lecturing": [
    "Kindergarten / ELC / Early Childhood",
    "Primary Teacher",
    "Middle School Teacher",
    "Secondary Teacher",
    "Adult / Tertiary",
    "Diverse Learning / Special Needs",
    "Indigenous Education",
    "Teacher Librarian",
    "Tutors / Coaching"
  ],
  "Leadership": [
    "Bursar",
    "Business / Finance Manager",
    "Chaplains / Pastoral Care",
    "Coordinator",
    "Curriculum / Learning",
    "Deputy / Assistant Head of School",
    "Development / Strategy / Process",
    "Head of Faculty / Department",
    "Human Resources",
    "Legal Officer",
    "Marketing / Communications",
    "Principal / Head of School",
    "Risk / Compliance / Safety"
  ],
  "Non-Teaching/Support": [
    "Admin / SSO / Non-Teaching",
    "Boarding Staff",
    "Bus Driver",
    "Canteen",
    "Career Advisors",
    "Cleaners",
    "Counsellors / Psychologists",
    "Diverse Learning / Special Needs",
    "Early Childhood Worker",
    "Finance / Accounts",
    "Gardeners / Maintenance",
    "HR / Recruitment Officer",
    "Instrumental Tuition",
    "IT / Tech Support",
    "Laboratory Technician",
    "Learning Support / Teacher's Aide",
    "Library Services",
    "Nurse / First Aid Officer",
    "OSHC",
    "Outdoor Education",
    "Social Worker",
    "Speech Pathologists",
    "Sports Coach",
    "Uniform"
  ]
};
const JobAddEdit_Employer = () => {
  window.Quill = Quill
  Quill.register('modules/imageResize', ImageResize);
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  };
  const formats = ['image'];
  const location = useLocation();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.userInfo);
  const employer = useSelector((state) => state.posts.employer);
  const jobPostCreditCount = useSelector((state) => state.posts.jobCredits);
  const [deductJobCredit, { data: dataJobCredits, isLoading: isLoadingJobCredits }] = useDeductJobCreditsMutation();


  const job = location.state?.job || null;

  const isAddMode = !job || Object.keys(job).length === 0;

  const [secondCategory, setSecondCategory] = useState(job?.thirdcategory ?? null);
  const [startTime, setStartTime] = useState(Date.now());
  let defaultValues
  let buttonText
  const { control, register, reset, handleSubmit, setValue, watch, getValues, formState: { errors }, setError } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues])
  })
  // const master_category_job_type = useWatch({
  //   name: "master_category_job_type",
  //   defaultValue: "default", // default value before the render
  // })
  let master_category_job_type = watch("master_category_job_type");
  if (!(master_category_job_type === 'Teaching and Lecturing' || master_category_job_type === 'Non-Teaching/Support' || master_category_job_type === 'Leadership')) {
    master_category_job_type = 'Teaching and Lecturing'
  }
  //alert(master_category_job_type)
  const [sendEmail, {
    isSuccess: isSuccessSendEmail,
    isError: isErrorSendEmail,
    error: errorSendEmail
  }] = useSendEmail3Mutation()
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (!job) {
      buttonText = "Post Job"
      reset({
        salary_period: 'yearly',
        currency: 'AUD',
        activation_date: new Date().toISOString().substring(0, 10),
        expiration_date: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10),
        remote: 'Onsite',
        position_type: ''
      })
      if (jobPostCreditCount > 0) {
        setValue("featured", true);
      }
      master_category_job_type = 'Teaching and Lecturing'
      setValue('master_category_job_type', 'Teaching and Lecturing')
    } else {
      console.log("=========Job=========", job)
      buttonText = "Update Job"
      reset(job);
      if (job.master_category_job_type === 'Teaching and Lecturing' || job.master_category_job_type === 'Non-Teaching/Support' || job.master_category_job_type === 'Leadership') {
        master_category_job_type = job.master_category_job_type
      } else {
        master_category_job_type = 'Teaching and Lecturing'
      }

      if (job.master_category_job_type === 'Teaching and Lecturing'  ) {
        setSecondCategory(job.subcategory_academic_jobs);
      } else if (job.master_category_job_type === 'Non-Teaching/Support') {
        setSecondCategory(job.subcategory_executive_jobs);
      } else if (job.master_category_job_type === 'Leadership') {
        setSecondCategory(job.subcategory_hr_jobs);
      }
      setValue('activation_date', new Date(job.activation_date).toISOString().split('T')[0])
      setValue('expiration_date', new Date(job.expiration_date).toISOString().split('T')[0])
      dispatch(setEmployer({ company_name: job?.employer?.company_name, employer_id: job.employer_id, logo: job.employer?.logo, clientType: job.clientType }))
    }
    // setValue('postedBy', userInfo.id)
    // if (employer) {
    //   setLogo(employer?.logo)
    //   reset(employer);
    // } else {
    //   reset({ featured: true, });
    // }
  }, [job]);
  useEffect(() => {
    register("description", { required: false });
    register("company_name1", { required: false });
    register("country", { required: false });
  }, [register]);
  useEffect(() => {
    if (!job) {
      setValue("company_name1", employer.company_name);
      setValue("employer_id", employer.employer_id);
      setValue("headlineOnly", employer.clientType === "HeadlineOnly");
    }
  }, [employer]);
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyCKEfoOIPz8l_6A8BByD3b3-ncwza8TNiA", //process.env.REACT_APP_GOOGLE,
    options: {
      componentRestrictions: { country: "Australia" },
    },
    onPlaceSelected: (place) => {
      //formik.setFieldValue("country", place.formatted_address);
    },
  });
  // if (!job) {
  //   buttonText="Post Job"
  //   defaultValues = {
  //     salary_period: 'yearly',
  //     currency: 'USD',
  //     activation_date: new Date().toISOString().substring(0, 10),
  //     expiration_date: new Date(new Date().getTime() + 90 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10),
  //     remote: 'Onsite'
  //   }
  // } else {
  //   console.log("=========Job=========", job)
  //   buttonText = "Update Job"
  //   defaultValues = job
  //   setValue('activation_date', job.activation_date.split(' ')[0]);
  // }
  const editorContent = watch("description");
  const editorContent1 = watch("employer_name");
  const [query, setQuery] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(true);
  const inputRef = useRef();
  const submitRef = useRef();
  const countryRef = useRef('');
  const { data: suggestions = [] } = useGetEmployerSuggestionsQuery({ query, country: countryRef.current || "" }, {
  });
  const [updateJob, { isLoading: isLoadingUpdateJob, isSuccess: isSuccessUpdateJob, isError: isErrorUpdateJob, error: errorUpdateJob, }] =
    useUpdateJobMutation();
  const [postAJob, { data: dataPostAJob, isLoading: isLoadingPostAJob, isSuccess: isSuccessPostAJob, isError: isErrorPostAJob, error: errorPostAJob }] = usePostAJobMutation();
  const [newList, setNewList] = useState([]);
  const [newListName, setNewListName] = useState(null);
  const [newList1, setNewList1] = useState([]);
  const [newList1Name, setNewList1Name] = useState(null);
  const userid = useSelector((state) => state.auth.userid);
  const country = useSelector((state) => state.auth.country);
  const company_name = useSelector((state) => state.auth.company_name);
  const navigate = useNavigate();

  const job_type = ["Full time", "Part time", "Contractor", "Sessional"];
  const onEditorStateChange = (editorState) => {
    setValue("description", editorState);
  };
  const onEditorStateChange1 = (company_name, employer_id, Region, country, logo, employerPageURL, clientType) => {
    // console.log("======onEditorStateChange1=====", company_name, employer_id, Region, country, logo)
    setValue("company_name1", company_name);
    setValue("employer_id", employer_id || 0);
    setValue("Region", Region);
    setValue("country", country);
    setValue("logo", logo);
    // setValue("clientType", clientType);
    //alert(id)
    dispatch(setEmployer({ company_name, employer_id, logo, employerPageURL, clientType }))
    if (employer_id) getCampuses({ id: employer_id })
  }
  function findValueForKey(array, keyToFind) {
    for (const obj of array) {
      const keys = Object.keys(obj);
      if (keys.includes(keyToFind)) {
        return obj[keyToFind];
      }
    }
    return null;
  }
  const [getCampuses, { data: dataCampuses }] = useGetCampusesMutation();

  const jobStatusDefault = job ? "Update Job" : (userInfo.employer_id !== undefined && userInfo.jobCredits === 0 ? "Save, Pay & Post" : "Post");
  const [jobStatus, setJobStatus] = useState(jobStatusDefault);

  let content, content1, content2;
  
  const onSubmit = async (data) => {
    console.log('data', data)
    return isAddMode
      ? createJob(data)
      : updateJob1({ id: job?.id, ...data })
  }
  async function createJob(data) {
    console.log(data)
    setJobStatus("Job Posting ......!")

    const headlineOnly = jobPostCreditCount === 0;

    const { data: { id } } = await postAJob({ ...data, postedBy: userInfo.id, headlineOnly: headlineOnly,});
    setJobStatus("Job POSTED!")

    // Deduct job credits if the user has more than 0 credits
    if (jobPostCreditCount > 0) {
      await deductJobCredit({ userId: userInfo.id });
    }


    // if (id > 0) {
    //   setJobStatus("Job POSTED! Now Sending Email ....")
    //   if (getValues("email1")) {
    //     const { email1, firstName1, title } = data
    //     await sendEmail({ email: email1, firstName: firstName1, jobId: id, jobTitle: title });
    //   }
    //   if (getValues("email2")) {
    //     const { email2, firstName2, title } = data
    //     await sendEmail({ email: email2, firstName: firstName2, jobId: id, jobTitle: title });
    //   }
    // }
  }
  async function updateJob1(data) {
    setJobStatus("Job Updating ......!")
    const response = await updateJob(data);
    console.log(response);
    if (response) {
      setJobStatus("Job UPDATED!")
      //submitRef.current.innerText = "Job POSTED!";
      setTimeout(() => {
        setJobStatus("Update Job")
        //submitRef.current.innerText = "Post Job";
      }, 10000);
    }
  }

  content = (
    <div className="mt-8">
      <form onSubmit={handleSubmit(onSubmit)}
        className="w-full flex justify-between ">
        <div className="w-[49%] flex flex-col gap-2">
          <div className="flex justify-start gap-1">
          <ul className=" flex justify-start items-end gap-4 px-1">
              <div className="card bg-base-100 border border-1 p-2 border-slate-300 w-[100px] h-[100px] grid place-items-center">
                <img
                  src={
                    userInfo?.logo
                      ? `https://academicjobs.s3.amazonaws.com/img/university-logo/${userInfo?.logo}`
                      : "/favicon.png"
                  }
                  alt={`${company_name}`}
                  className="object-contain rounded-md bg-white"
                />
              </div>
            </ul>
            <div className=" w-[100%]">
              <div className="w-[100%] mx-auto flex flex-col gap-1">
                <label className="label-text font-bold pb-2 text-lg">Institution Name</label>

                {userInfo?.employer_id === undefined ? (
                  <UniSearchBlock
                    register={register}
                    field="employer_name"
                    customKey="employer_name"
                    label="employer_name"
                    value1={userInfo?.company_name}
                    forceClass="mb-6"
                    onChange={onEditorStateChange1}
                  />
                ) : (
                  <>
                    {userInfo?.company_name && (
                      <label className="label-text font-black pb-2 text-blue-800 text-xl">{userInfo?.company_name}</label>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>





          {/* <label className="label-text font-bold text-md">Institution Name</label>
                    <UniSearchBlock
                        register={register}
                        field="employer_name"
                        customKey="employer_name"
                        label="employer_name"
                        value1={employer.company_name}
                        forceClass="mb-6"
                        onChange={onEditorStateChange1}
                    />
                    <SelectBlock2 list={["Kindergarten", "Primary", "Secondary", "Vocational Training", "Higher Education"]} field="schoolType1" label="School Type" register={register} errors={errors} forceClass="join-item rounded-l-none min-h-[34px] font-bold" /> */}






          {/* <InputBlock2 type="text" field="schoolType1" label="" register={register} errors={errors} forceClass="" /> */}
          <label className="mt-[2rem] text-[#e74b7f] font-bold">Job Title</label>
          <InputBlock2 type="text" field="title" label="" register={register} errors={errors} forceClass="" />
          <div className="mb-6 mt-4">
            <label className="text-[#e74b7f] font-bold">Job Description</label>
            <ReactQuill 
              modules={modules}
              className="w-full mb-6"
              value={editorContent}
              onChange={onEditorStateChange}
            />
          </div>
        </div>
        <div className="w-[49%] flex flex-col ">
          {/* <div className="py-[2rem] text-4xl font-black text-yellow-500 flex flex-col gap-2 ">
                        {
                            isAddMode
                                ? <>
                                </>
                                : <div className="flex justify-start gap-10">
                                    <h1 className="text-red-600">Edit Job</h1>
                                    <Link
                                        to={`https://www.teachingjobs.com.au/jobs/${"company_name"
                                            .replace(/\W+/g, "-")
                                            .toLowerCase()}/${job.id}?active=true`}
                                        className="btn bg-yellow-500"
                                        target="_blank" rel="noopener noreferrer"
                                    >
                                        view Posted Job
                                    </Link>
                                </div>
                        }
                    </div> */}



          <div className="mt-[2rem] font-bold text-[#e74b7f]">Job Type</div>
          <JobKeywordSearchBlock
            customKey="Enter a keyword"
            value1={secondCategory}
            forceClass="mb-8"
            required={true}
            onSelect={(realCtg, category2, category01, category01A, category02, category02A) => {
              console.log(category01, category01A);
              console.log(realCtg, category2);
              setValue(realCtg, category2);
              if (category01) setValue(category01, category01A);
              if (category02) setValue(category02, category02A);
              if (realCtg == "thirdcategory") setSecondCategory(category02A);
            }}
            className="w-full"
          />
          {/* <div className="col-start-2 w-full  flex flex-col  items-start ">
                        <label className="label-text font-bold pb-2 text-md">Master Category: General Job Type</label>
                        <ul className="w-full list-none grid-cols-3 flex flex-wrap gap-2 p-0 mb-4">
                            {job_category["master_category_job_type"].map(
                                (position, index) => (
                                    <div className=" w-1/4 flex ">
                                        <div className=" text-left">
                                            <input
                                                type="radio"
                                                className="radio radio-xs mr-1"
                                                value={position}
                                                {...register("master_category_job_type")}
                                            />
                                        </div>
                                        <label className={`text-xs text-left text-gray-600 `}>
                                            {position}
                                        </label>
                                    </div>
                                )
                            )}
                        </ul>
                    </div> */}
          {/* <div className={`w-full  flex flex-col  items-start     
                    ${master_category_job_type === "Teaching and Lecturing"
                            ? "block"
                            : "hidden"
                        }`}>
                        <label className="label-text font-bold pb-2  text-md mt-4">Teaching Types</label>
                        <ul className="w-full list-none flex flex-wrap gap-2 p-0 mb-4">
                            {job_category["Teaching and Lecturing"].map((position, index) => (
                                <div className=" w-1/4 flex ">
                                    <div className=" text-left">
                                        <input
                                            type="radio"
                                            className="radio radio-xs mr-1"
                                            value={position}
                                            {...register("subcategory_academic_jobs")}
                                            onChange={(e) => {
                                                setSecondCategory(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <label className={`text-xs text-left text-gray-600 `}>
                                        {position}
                                    </label>
                                </div>
                            ))}
                        </ul>
                    </div> */}
          {/* <div className={` w-full  flex flex-col  items-start     
                    ${master_category_job_type === "Non-Teaching/Support"
                            ? "block"
                            : "hidden"
                        }`}>
                        <label className="label-text font-bold pb-2  text-md mt-4">Non-Teaching/Support Types</label>
                        <ul className="w-full list-none flex flex-wrap gap-2 p-0 mb-4">
                            {job_category["Non-Teaching/Support"].map((position, index) => (
                                <div className=" w-1/4 flex ">
                                    <div className=" text-left">
                                        <input
                                            type="radio"
                                            className="radio radio-xs mr-1"
                                            value={position}
                                            {...register("subcategory_executive_jobs")}
                                            onChange={(e) => {
                                                setSecondCategory(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <label className={`text-xs text-left text-gray-600 `}>
                                        {position}
                                    </label>
                                </div>
                            ))}
                        </ul>
                    </div>
                    <div className={`w-full  flex flex-col  items-start     
          ${master_category_job_type === "Leadership"
                            ? "block"
                            : "hidden"
                        }`}>
                        <label className="label-text font-bold pb-2  text-md mt-4">Leadership Types</label>
                        <ul className="w-full list-none flex flex-wrap gap-2 p-0 mb-4">
                            {job_category["Leadership"].map((position, index) => (
                                <div className=" w-1/4 flex ">
                                    <div className=" text-left">
                                        <input
                                            type="radio"
                                            className="radio radio-xs mr-1"
                                            value={position}
                                            {...register("subcategory_hr_jobs")}
                                            onChange={(e) => {
                                                setSecondCategory(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <label className={`text-xs text-left text-gray-600 `}>
                                        {position}
                                    </label>
                                </div>
                            ))}
                        </ul>
                    </div> */}
          {/* <ul className="w-full list-none flex flex-wrap gap-2 p-0 mb-4">
                        {job_category["Teaching and Lecturing"].map((category, index) => (
                            <div
                                className={` flex flex-col items-start 
          ${secondCategory === category &&
                                    master_category_job_type === "Teaching and Lecturing"
                                        ? "block"
                                        : "hidden"
                                    }`}
                            >
                                <label className="label-text pb-2  text-lg mt-4">
                                    {category} Specialties
                                </label>
                                <ul className="w-full list-none flex flex-wrap gap-2 p-0 mb-4">
                                    {job_category[category]?.map((position, index) => (
                                        <div className=" w-1/4 flex ">
                                            <div className=" text-left">
                                                <input
                                                    type="radio"
                                                    className="radio radio-warning radio-xs mr-1"
                                                    value={position}
                                                    {...register("thirdcategory")}
                                                />
                                            </div>
                                            <label className={`text-xs text-left text-gray-600 `}>
                                                {position}
                                            </label>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </ul> */}
          {/* <div className={`w-full  flex flex-col  items-start   mt-2  `}>
                        <InputBlock2 className="font-bold text-md" type="text" field="subcategoryOthers" label='If "others" is selected, fill the detail of others here:' register={register} errors={errors} forceClass="font-bold text-md" />
                    </div> */}
          {/* <div className={` w-full  flex flex-col  items-start     
                  `}>
                        <label className="label-text font-bold pb-2  text-md mt-4">{`${master_category_job_type} Positions`}</label>
                        <ul className="w-full list-none flex flex-wrap gap-2 p-0 mb-4">
                            {academic_positions[master_category_job_type].map((position, index) => (
                                <div key={index} className=" w-1/4 flex ">
                                    <div className=" text-left">
                                        <input
                                            key={Math.random()}
                                            type="checkbox"
                                            value={position}
                                            defaultChecked={job?.positiontypes.some(
                                                (p) => p.positionType === position
                                            )}
                                            {...register("positiontypes")}
                                        />
                                    </div>
                                    <label className={`text-xs text-left text-gray-600 `}>
                                        {position}
                                    </label>
                                </div>
                            ))}
                        </ul>
                    </div> */}
          <div
            className={`w-full flex flex-col items-start ${master_category_job_type === "Teaching and Lecturing" || master_category_job_type === "Leadership"
                ? "block"
                : "hidden"
              }`}
          >
            <label className="label-text font-bold pb-2 text-md mt-4">{`Subject: ${master_category_job_type}`}</label>
            <select
              className="select select-bordered w-full mt-2"
              {...register("subject")}
            >
              <option value="" disabled selected>
                Select a subject
              </option>
              {subjects.map((subject, index) => (
                <option key={index} value={subject}>
                  {subject}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-[2rem] font-bold text-[#e74b7f]">Employment Type</div>
          <SelectBlock2 list={job_type} field="job_type" label="" register={register} errors={errors} forceClass=" font-bold" />
          <div className="mt-[2rem] font-bold text-[#e74b7f]">Location Details</div>
          <div>
            <Autocomplete
              className="border p-1 rounded-lg mt-2 text-pink-400"
              style={{ width: "100%" }}
              apiKey="AIzaSyCKEfoOIPz8l_6A8BByD3b3-ncwza8TNiA"
              {...register("location1")}
              onPlaceSelected={(place) => {
                const addressComponents = place.address_components || [];
                let city = "";
                let state = "";
                let country1 = "";
                let postalCode = "";
                addressComponents.forEach((component) => {
                  const types = component.types || [];
                  if (types.includes("locality")) {
                    city = component.long_name;
                  } else if (types.includes("administrative_area_level_1")) {
                    state = component.long_name;
                  } else if (types.includes("country")) {
                    country1 = component.long_name;
                  } else if (types.includes("postal_code")) {
                    postalCode = component.long_name;
                  }
                });

                setValue("location", place.formatted_address);
                setValue("city", city);
                setValue("state", state);
                setValue("country", country1);
                setValue("region", country1);
                setValue("postalCode", postalCode);
              }}
              options={{
                types: ["geocode", "establishment"],
              }}
              onChange={(e) => setValue("location", e.target.value)}
            />
            <div>{job?.location}</div>
            <div className="flex items-center justify-start mt-4">
              <div className="form-control items-start mb-2 mr-4">
                <label className="flex items-start justify-start label cursor-pointer">
                  <input {...register("remote")} type="radio" className="radio radio-warning radio-xs mr-1" value="Onsite" />
                  <span className="label-text font-bold pb-2 text-sm">Onsite</span>
                </label>
              </div>
              <div className="form-control items-start mb-2 mr-4">
                <label className="flex items-start justify-start label cursor-pointer">
                  <input {...register("remote")} type="radio" className="radio radio-warning radio-xs mr-1" value="Remote" />
                  <span className="label-text font-bold pb-2 text-sm">Remote</span>
                </label>
              </div>
              <div className="form-control items-start mb-2">
                <label className="flex items-start justify-start label cursor-pointer">
                  <input {...register("remote")} type="radio" className="radio radio-warning radio-xs mr-1" value="Hybrid" />
                  <span className="label-text font-bold pb-2 text-sm">Hybrid</span>
                </label>
              </div>
            </div>
          </div>
          <div className=" font-bold text-[#e74b7f]">Salary Range</div>
          <div className="join flex mb-6 gap-2">
            <div className="max-w-[80px]">
              <div>
                <SelectBlock2 list={currencies} field="currency" label="Currency" register={register} errors={errors} forceClass="join-item rounded-r-none min-h-[34px] font-bold" />
              </div>
            </div>
            <div className="flex-grow">
              <InputBlock2 type="text" field="salary_from" label="$From" register={register} errors={errors} forceClass="rounded-none min-h-[34px] font-bold" />
            </div>
            <div className="flex-grow">
              <InputBlock2 type="text" field="salary_to" label="$To" register={register} errors={errors} forceClass="rounded-none min-h-[34px] font-bold" />
            </div>
            <div className="max-w-[100px]">
              <div>
                <SelectBlock2 list={["yearly", "monthly", "weekly", "daily", "hourly"]} field="frequency" label="Frequency" register={register} errors={errors} forceClass="join-item rounded-l-none min-h-[34px] font-bold" />
              </div>
            </div>
          </div>
          <div className="font-bold text-[#e74b7f]">How to Apply</div>
          <InputBlock2 type="text" field="how_to_apply" label="" register={register} errors={errors} forceClass=""
          />
          <div className="grid grid-cols-2 gap-4 mb-4 mt-8">
            <InputBlock2 type="date" field="activation_date" label="Post Date" register={register} errors={errors} forceClass="mb-6 font-bold text-xl"
            />
            <InputBlock2 type="date" field="expiration_date" label="Expiration Date" register={register} errors={errors} forceClass="mb-6 font-bold"
            />
          </div>
          <div className="flex justify-start gap-1">
            <button
              type="submit"
              className="btn btn-aj"
              ref={submitRef}
            >
              {jobStatus}
            </button>
            {content2}
          </div>
        </div>
      </form>
      {content1}
    </div>
  );
  return (
    <div className="overflow-y w-full">
      {content}
    </div>
  );
};
export default JobAddEdit_Employer;
