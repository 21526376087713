import React, { useState, useEffect } from 'react';
import { useGetAllUsersQuery, useRegisterUserMutation } from './auth/authApiSlice';
import { useGetEmployerQuery } from '../store/apiSlice';

const UserList = () => {
  const { data, error, isLoading } = useGetAllUsersQuery();
  const [registerUser, { isLoading: isRegistering, isSuccess, isError, error: registerError }] = useRegisterUserMutation(); 
  const [formData, setFormData] = useState({
    userType: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    organization_name: '',
    country: '',
    state: '',
    postCode: '',
    phone: '',
    employer_id: '',
  });
  const [message, setMessage] = useState('');
  const [employerId, setEmployerId] = useState(0);

  // Fetch employer data based on employer_id input
  const { data: employerData, isSuccess: isEmployerSuccess } = useGetEmployerQuery(employerId, { skip: !employerId });

  useEffect(() => {
    if (isEmployerSuccess && employerData) {
      setFormData((prevData) => ({
        ...prevData,
        organization_name: employerData.company_name,
      }));
    }
  }, [isEmployerSuccess, employerData]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEmployerIdChange = (e) => {
    const id = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      employer_id: id,
    }));
    setEmployerId(id);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await registerUser(formData).unwrap(); 
      setMessage('User successfully registered');
      setFormData({
        userType: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        organization_name: '',
        country: '',
        state: '',
        postCode: '',
        phone: '',
        employer_id: '',
      });
    } catch (err) {
      setMessage('Error registering user');
    }
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="p-4">
              <div className="flex justify-between space-x-4 mb-8">
        {/* Employees Card */}
        <div className="flex-1 bg-white shadow-md rounded-lg p-6 max-h-[600px] overflow-y-auto custom-scrollbar">
          <h2 className="text-xl font-semibold text-center mb-4">Employees</h2>
          <ul className="list-none">
            {data?.employees.map((employee) => (
              <li key={employee.id} className="py-2 border-b border-gray-200">
                <p className="text-gray-700 font-medium">
                  {employee.firstName} {employee.lastName}
                </p>
                <p className="text-gray-500 text-sm">{employee.email}</p>
              </li>
            ))}
          </ul>
        </div>

        {/* Employers Card */}
        <div className="flex-1 bg-white shadow-md rounded-lg p-6 max-h-[600px] overflow-y-auto custom-scrollbar">
          <h2 className="text-xl font-semibold text-center mb-4">Employers</h2>
          <ul className="list-none">
            {data?.employers.map((employer) => (
              <li key={employer.id} className="py-2 border-b border-gray-200">
                <p className="text-gray-700 font-medium">
                  {employer.firstName} {employer.lastName}
                </p>
                <p className="text-gray-500 text-sm">{employer.email}</p>
                {employer.Employer ? (
                  <p className="text-gray-600 text-sm mt-2">
                    <strong>Company: </strong>
                    {employer.Employer.company_name}
                  </p>
                ) : (
                  <p className="text-gray-600 text-sm mt-2">
                    <strong>Company: </strong> Not associated with a company
                  </p>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6 w-1/2 mx-auto">
        <h2 className="text-xl font-semibold text-center mb-4">Register New User</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* User Type Selection */}
          <select
            name="userType"
            value={formData.userType}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          >
            <option value="">Select User Type</option>
            <option value="employee">Employee</option>
            <option value="employer">Employer</option>
          </select>

          <div className="flex space-x-4">
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div className="flex space-x-4">

          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
          <input
            type="text"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
        </div>
          {/* Conditionally render fields based on userType */}
          {formData.userType === 'employer' && (
            <>
              <input
                type="text"
                name="employer_id"
                placeholder="Employer ID"
                value={formData.employer_id}
                onChange={handleEmployerIdChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
              <input
                type="text"
                name="organization_name"
                placeholder="Organization Name"
                value={formData.organization_name}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-md"
                readOnly
              />
            </>
          )}

          <div className="flex space-x-4">
            <input
              type="text"
              name="country"
              placeholder="Country"
              value={formData.country}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
            <input
              type="text"
              name="state"
              placeholder="State"
              value={formData.state}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div className="flex space-x-4">
            <input
              type="text"
              name="postCode"
              placeholder="Post Code"
              value={formData.postCode}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
          >
            Register User
          </button>
        </form>
        {message && <p className="mt-4 text-center text-green-500">{message}</p>}
      </div>
    </div>
  );
};

export default UserList;
