import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  useGetJobKeywordSuggestionsQuery,
  useGetJobKeywordSuggestionsRestrictedQuery,
} from "../store/apiSlice";

const UniSearchBlock = ({
  field,
  country,
  label,
  customKey,
  value1,
  onSelect,
  onInputChange,
  forceClass,
  category1, // Add category1 as a prop
  btnColor,
}) => {
  console.log(category1);
  const countryRef = useRef("");
  const [query, setQuery] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(value1 || "");

  // Define the query object based on whether category1 is present
  const queryParams = category1 ? { query, category1 } : { query };

  // Call both hooks but only use the appropriate one based on the presence of category1
  const { data: restrictedSuggestions = [] } =
    useGetJobKeywordSuggestionsRestrictedQuery(queryParams, {
      skip: !category1,
    });
  const { data: suggestions = [] } = useGetJobKeywordSuggestionsQuery(
    queryParams,
    {
      skip: !!category1,
    }
  );

  const handleInputClick = () => {
    setShowSuggestions(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    setShowSuggestions(true);
    setQuery(e.target.value);
  };

  useEffect(() => {
    setQuery(value1 || "");
  }, [value1]);

  // Decide which suggestions to use based on whether category1 is provided
  const finalSuggestions = category1 ? restrictedSuggestions : suggestions;

  return (
    <div className="w-full bg-white">
      <input
        ref={inputRef}
        type="text"
        value={query}
        onChange={handleInputChange}
        onClick={handleInputClick}
        className={`w-full input input-md input-bordered focus:outline-none focus:border-orange-500`}
        autoComplete="one-time-code"
        name={customKey}
        placeholder={label}
      />
      {showSuggestions && (
        <ul className="mt-2 list-none z-10">
          {finalSuggestions.map(
            (
              {
                category1,
                realCtg,
                category2,
                category01,
                category01A,
                category02,
                category02A,
                link,
              },
              index
            ) => (
              <li
                key={index}
                className="bg-white  w-full py-1 px-1 border-b border-gray-300 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  setQuery(category2);
                  onSelect(
                    realCtg,
                    category2,
                    category01,
                    category01A,
                    category02,
                    category02A,
                    link
                  );
                }}
              >
                <div className="w-[100%]">
                  <span className="text-sm font-bold">{category2}</span>
                  {category1 && (
                    <span className="text-xs">{` (${category1})`}</span>
                  )}
                </div>
              </li>
            )
          )}
        </ul>
      )}
    </div>
  );
};

export default UniSearchBlock;
