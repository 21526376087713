import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials, setUserInfo } from '../views/auth/authSlice'
import { setJobCredits } from "./postsSlice"
const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ENV === 'development'
        ? 'http://localhost:3500/api'
        //  ? 'https://api2.sciencejobs.com.au/api'
        : 'https://api.sciencejobs.com.au/api',
    //'https://api1.sciencejobs.com.au/api',
    //'http://localhost:7997/api',
    //'https://api.academicjobs.com/api',
    //credentials: 'same-origin',
    mode: "cors",
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token
        if (token) {
            headers.set("authorization", `Bearer ${token}`)
        }
        headers.set('Access-Control-Allow-Origin', '*')
        headers.set('Content-Type', 'application/json');
        return headers
    }
})
const baseQueryWithReauth = async (args, api, extraOptions) => {
    // console.log(args) // request url, method, body
    // console.log(api) // signal, dispatch, getState()
    // console.log(extraOptions) //custom like {shout: true}
    let result = await baseQuery(args, api, extraOptions)
    // If you want, handle other status codes, too
    if (result?.error?.status === 403) {
        console.log('sending refresh token')
        // send refresh token to get new access token 
        const refreshResult = await baseQuery('/auth/refresh', api, extraOptions)
        if (refreshResult?.data) {
            // store the new token 
            api.dispatch(setCredentials({ ...refreshResult.data }))
            // retry original query with new access token
            result = await baseQuery(args, api, extraOptions)
        } else {
            if (refreshResult?.error?.status === 403) {
                refreshResult.error.data.message = "Your login has expired."
            }
            return refreshResult
        }
    }
    return result
}
export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: ['employers', 'employer', 'job', 'jobs', 'orderedProducts'],
    endpoints: (builder) => ({
        shareJob: builder.mutation({
            query: (data) => ({
                url: `/sharejob`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            invalidatesTags: [],
        }),
        sendEmail: builder.mutation({
            query: (data) => ({
                url: `/sendemail`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            invalidatesTags: [],
        }),
        talentPoolEmail: builder.mutation({
            query: (data) => ({
                url: `/talentPoolEmail`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            invalidatesTags: [],
        }),
        sendEmail1: builder.mutation({
            query: (data) => ({
                url: `/sendemail1`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            invalidatesTags: [],
        }),
        sendEmail3: builder.mutation({
            query: (data) => ({
                url: `/sendemail3`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            invalidatesTags: [],
        }),
        getJobs: builder.query({
            query: (data) => ({
                url: `/jobs`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
                return queryArgs
                //const { q, l } = queryArgs
                //return { q, l }
                //return { uniqueKey: `${q}-${l}` }
            },
            transformResponse: (responseData) => {
                return responseData.data.jobs
            },
            // merge: (currentCache, newItems) => {
            //     console.log(newItems)
            //     if (newItems) currentCache.push(...newItems);
            // },
            // forceRefetch({ currentArg, previousArg }) {
            //     return currentArg !== previousArg;
            // },
            providesTags: ['jobs'],
            //invalidatesTags: ['jobs'],
        }),
        getJobs2: builder.query({
            query: (data) => ({
                url: `/jobs2`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
                return queryArgs
                //const { q, l } = queryArgs
                //return { q, l }
                //return { uniqueKey: `${q}-${l}` }
            },
            transformResponse: (responseData) => {
                return responseData.data?.jobs || []
                
            },
            providesTags: ['jobs'],
            invalidatesTags: ['jobs'],
        }),
        getJobs3: builder.query({
            query: (data) => ({
                url: `/jobs3`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
                return queryArgs
                //const { q, l } = queryArgs
                //return { q, l }
                //return { uniqueKey: `${q}-${l}` }
            },
            transformResponse: (responseData) => {
                return responseData.data?.jobs || []
                
            },
            providesTags: ['jobs3'],
            invalidatesTags: ['jobs3'],
        }),
        getQty: builder.query({
            query: (data) => ({
                url: `/jobQty`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['jobsQty'],
            invalidatesTags: ['jobsQty'],
        }),
        getQty1: builder.query({
            query: (data) => ({
                url: `/jobQty1`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['jobsQty1'],
            invalidatesTags: ['jobsQty1'],
        }),
        getJobCredits: builder.query({
            query: (data) => ({
                url: `/getJobCredits`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['jobCredits'],
            invalidatesTags: ['jobCredits'],
        }),
        getSalesLeadsList: builder.query({
            query: (data) => ({
                url: `/getSalesLeadsList`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['salesLeadsList'],
            invalidatesTags: ['salesLeadsList'],
        }),
        getEmployers: builder.query({
            query: (data) => ({
                url: `/employers`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
                return queryArgs
                //const { q, l } = queryArgs
                //return { q, l }
                //return { uniqueKey: `${q}-${l}` }
            },
            transformResponse: (responseData) => {
                return responseData.data
            },
            // merge: (currentCache, newItems) => {
            //     console.log(newItems)
            //     if (newItems) currentCache.push(...newItems);
            // },
            // forceRefetch({ currentArg, previousArg }) {
            //     return currentArg !== previousArg;
            // },
            providesTags: ['employers'],
            //invalidatesTags: ['jobs'],
        }),
        getFilters: builder.query({
            query: (data) => ({
                url: `/filters`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['filters'],
            invalidatesTags: ['filters'],
        }),
        getOrderedProducts: builder.query({
            query: (data) => ({
                url: `/getOrderedProducts`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                //alert("qqq")
                //api.dispatch(setJobCredits ())
                return responseData.data
            },
            providesTags: ['orderedProducts'],
            invalidatesTags: ['orderedProducts'],
        }),
        emailInvoice: builder.mutation({
            query: invoiceId => ({
                url: '/emailInvoice',
                method: 'POST',
                body: { invoiceId }
            }),
        }),
        addOrderedProducts: builder.mutation({
            query: (data) => ({
                url: `/addOrderedProducts`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setJobCredits(data.jobCredits));
                } catch (err) {
                    console.log(err)
                }
            },
            transformResponse: (responseData) => {
                console.log("finish addOrderedProducts, start invalata tag")
                return responseData.data
            },
            providesTags: ['orderedProducts'],
            invalidatesTags: ['User', 'orderedProducts', 'employer',],
        }),
        deductJobCredits: builder.mutation({
            query: (data) => ({
                url: `/deductJobCredit`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setJobCredits(data.jobCredits));
                } catch (err) {
                    console.log(err)
                }
            },
            transformResponse: (responseData) => {
                return responseData.data
            },
        }),
        getUserById: builder.query({
            query: (data) => ({
                url: `/auth/getUserById`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setJobCredits(data.task.jobCredits));
                } catch (err) {
                    console.log(err)
                }
            },
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['User'],
        }),
        postAJob: builder.mutation({
            query: (data) => ({
                url: `/postAJob`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    //dispatch(setUserInfo(data))
                    //dispatch(setUserid(userid))
                    //dispatch(setEmail1(email))
                } catch (err) {
                    console.log(err)
                }
            },
            transformResponse: (responseData) => {
                return responseData.data
            },
            //providesTags: ['orderedProducts'],
            invalidatesTags: ['job', 'jobs', 'orderedProducts'],
        }),
        createEmployer: builder.mutation({
            query: (data) => ({
                url: `/createEmployer`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    //dispatch(setUserInfo(data))
                    //dispatch(setUserid(userid))
                    //dispatch(setEmail1(email))
                } catch (err) {
                    console.log(err)
                }
            },
            transformResponse: (responseData) => {
                return responseData.data
            },
            //providesTags: ['orderedProducts'],
            invalidatesTags: ['employer', 'employers',],
        }),
        downloadInvoice: builder.mutation({
            query: (data) => ({
                url: `/downloadInvoice`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['invoices'],
        }),
        updateEmployer: builder.mutation({
            query: (data) => ({
                url: `/updateEmployer`,
                method: 'PATCH',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData
            },
            invalidatesTags: ['employer', "employers", 'job', 'jobs'],
        }),
        updateUser: builder.mutation({
            query: (data) => ({
                url: `/auth/updateUser`,
                method: 'PATCH',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData
            },
            invalidatesTags: ['user2', "users"],
        }),
        updateContact: builder.mutation({
            query: (data) => ({
                url: `/updateContact`,
                method: 'PATCH',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData
            },
            invalidatesTags: ['OtherContacts'],
        }),
        updateNote: builder.mutation({
            query: (data) => ({
                url: `/updateNote`,
                method: 'PATCH',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData
            },
            invalidatesTags: ['salesNotesByUser', 'salesNotes'],
        }),
        updatePostRegions: builder.mutation({
            query: (data) => ({
                url: `/updatePostRegions`,
                method: 'PATCH',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData
            },
            invalidatesTags: ['jobs3'],
        }),
        updateJob: builder.mutation({
            query: (data) => ({
                url: `/updateJob`,
                method: 'PATCH',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData
            },
            invalidatesTags: ['job', 'jobs'],
        }),
        getJobById: builder.query({
            query: (id) => ({
                url: `job/${id}`,
                method: 'get',
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['job'],
        }),
        getOrderById: builder.query({
            query: (data) => ({
                url: `/getOrderById`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['order'],
        }),
        getEmployer: builder.query({
            query: (id) => ({
                url: `employerById/${id}`,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['employer', 'employers'],
        }),
        getSingleQA: builder.query({
            query: () => ({
                url: `employer/3739`,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['employer'],
        }),
        getJobQtyAgent: builder.query({
            query: () => ({
                url: `getJobQtyAgent`,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['JobQtyAgent'],
        }),
        getJobPaddingEmployers: builder.query({
            query: () => ({
                url: `getJobPaddingEmployers`,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['JobPaddingEmployer'],
        }),
        getMax5JobsEmployers: builder.query({
            query: (agentId) => ({
                url: `/${agentId}`,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data.jobss
            },
            providesTags: ['Max5JobsEmployers'],
        }),
        getAUNZEmployers: builder.query({
            query: (data) => ({
                url: 'getAUNZEmployers',
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['AUNZEmployers'],
        }),
        getAUNZEmployersSalesLeads: builder.query({
            query: (data) => ({
                url: 'getAUNZEmployersSalesLeads',
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['AUNZEmployersSalesLeads'],
        }),
        getJobsByEmployer: builder.query({
            query: (id) => ({
                url: `job/${id}`,
                method: `GET`,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data.jobs
            },
            providesTags: ['jobs'],
        }),
        getEmployerSuggestions: builder.query({
            query: ({ query, country }) => ({
                url: 'getEmployerSuggestions',
                method: 'POST',
                body: { query, country },
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['employerSuggestions'],
            invalidatesTags: ['employerSuggestions'],
        }),
        getEmployersByLocation: builder.query({
            query: (suburb) => ({
                url: '/getEmployersByLocation', // Adjust the endpoint if necessary
                method: 'POST',
                body: { suburb }, // Wrap suburb in an object
            }),
            transformResponse: (responseData) => {
                if (responseData?.status === 'Success') {
                    return responseData.data;
                } else {
                    return []; // Return an empty array or handle error appropriately
                }
            },
            providesTags: ['Employers'], // Optional: use tags if you're managing caching or invalidation
        }),
        
        getJobKeywordSuggestions: builder.query({
            query: ({ query }) => ({
                url: 'getJobKeywordSuggestions',
                method: 'POST',
                body: { query },
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['employerSuggestions'],
            invalidatesTags: ['employerSuggestions'],
        }),
        getJobKeywordSuggestionsRestricted: builder.query({
            query: ({ query, category1 }) => ({
                url: 'getJobKeywordSuggestionsRestricted',
                method: 'POST',
                body: { query, category1 },
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['employerSuggestions'],
            invalidatesTags: ['employerSuggestions'],
        }),
        
        getAllEmployers: builder.query({
            query: (data) => ({
                url: 'getAllEmployers',
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['allEmployers'],
            invalidatesTags: ['allEmployers'],
        }),
        getUserSuggestions: builder.query({
            query: ({ query, country }) => ({
                url: 'getUserSuggestions',
                method: 'POST',
                body: { query, country },
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['userSuggestions'],
            invalidatesTags: ['userSuggestions'],
        }),
        getCampuses: builder.mutation({
            query: ({ id }) => ({
                url: 'getCampuses',
                method: 'POST',
                body: { id },
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['campuses'],
        }),
        getSalesNotesByUser: builder.query({
            query: (data) => ({
                url: `/getSalesNotesByUser`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['salesNotesByUser'],
            invalidatesTags: ['salesNotesByUser'],
        }),
        getSalesDashboardByUser: builder.query({
            query: (data) => ({
                url: `/getSalesDashboardByUser`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['salesDashboardByUser'],
            invalidatesTags: ['salesDashboardByUser'],
        }),
        addSalesNotes: builder.mutation({
            query: (data) => ({
                url: `/addSalesNotes`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData
            },
            invalidatesTags: ['salesNotes', 'salesNotesByUser'],
        }),
        getSalesNotes: builder.query({
            query: (data) => ({
                url: `/getSalesNotes`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['salesNotes'],
            invalidatesTags: ['salesNotes'],
        }),
        addOtherContacts: builder.mutation({
            query: (data) => ({
                url: `/addOtherContacts`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData
            },
            invalidatesTags: ['OtherContacts', 'OtherContactsByUser'],
        }),
        getOtherContacts: builder.query({
            query: (data) => ({
                url: `/getOtherContacts`,
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['OtherContacts'],
            invalidatesTags: ['OtherContacts'],
        }),
        GetMainSecondContacts: builder.query({
            query: () => ({
                url: '/getMainSecondContacts',
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData.data
            },
            providesTags: ['MainSecondContacts'],
        }),
        GetMainSecondOtherContacts: builder.query({
            query: (country) => ({
                url: `/getMainSecondOtherContacts?country=${country}`,
                method: 'GET',
            }),
            transformResponse: (responseData) => {
                return responseData.data;
            },
            providesTags: ['MainSecondOtherContacts'],
        }),
        GetAcademicLeadJobs: builder.query({
            query: () => ({
                url: '/getAcademicLeadJobs',
                method: 'POST',
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData
            },
            providesTags: ['GetAcademicLeadJobs'],
        }),
        ShareJobAcademicLead: builder.query({
            query: (data) => ({
                url: '/shareJobAcademicLead',
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData
            },
            providesTags: ['ShareAcademicLeadJobs'],
        }),
        SendClientAdminRequestEmail: builder.mutation({
            query: (data) => ({
                url: '/sendClientAdminRequestEmail',
                method: 'POST',
                body: data,
                mode: 'cors',
            }),
            transformResponse: (responseData) => {
                return responseData
            },
            providesTags: ['sendClientAdminRequestEmail'],
        }),
        createInvoice: builder.mutation({
            query: (data) => ({
              url: `/invoices/create`,
              method: 'POST',
              body: data,
              mode: 'cors',
            }),
            transformResponse: (response, meta, arg) => {
                return { data: response, status: meta.response.status };
            },
          }),
          getJobBoards: builder.query({
            query: () => '/jobBoards',
            transformResponse: (responseData) => responseData,
            providesTags: (result) =>
              result
                ? [
                    ...result.map(({ id }) => ({ type: 'JobBoard', id })),
                    { type: 'JobBoard', id: 'LIST' },
                  ]
                : [{ type: 'JobBoard', id: 'LIST' }],
          }),
      
          // Create a new job board
          createJobBoard: builder.mutation({
            query: (data) => ({
              url: '/jobBoards',
              method: 'POST',
              body: data,
              headers: {
                'Content-Type': 'application/json',
              },
            }),
            transformResponse: (responseData) => responseData,
            invalidatesTags: [{ type: 'JobBoard', id: 'LIST' }],
          }),
      
          // Update an existing job board by ID
          updateJobBoard: builder.mutation({
            query: ({ id, ...data }) => ({
              url: `/jobBoards/${id}`,
              method: 'PUT',
              body: data,
              headers: {
                'Content-Type': 'application/json',
              },
            }),
            transformResponse: (responseData) => responseData,
            invalidatesTags: (result, error, { id }) => [{ type: 'JobBoard', id }],
          }),
      
          // Delete a job board by ID
          deleteJobBoard: builder.mutation({
            query: (id) => ({
              url: `/jobBoards/${id}`,
              method: 'DELETE',
            }),
            transformResponse: (responseData) => responseData,
            invalidatesTags: (result, error, { id }) => [{ type: 'JobBoard', id }],
          }),
    }),
})
export const {
    useUpdatePostRegionsMutation,
    useGetJobs3Query,
    useUpdateContactMutation,
    useUpdateNoteMutation,
    useGetSalesNotesQuery,
    useAddSalesNotesMutation,
    useGetOtherContactsQuery,
    useAddOtherContactsMutation,
    useGetSalesNotesByUserQuery,
    useGetSalesDashboardByUserQuery,
    useGetAllEmployersQuery,
    useGetSalesLeadsListQuery,
    useGetJobQtyAgentQuery,
    useEmailInvoiceMutation,
    useGetUserByIdQuery,
    useGetCampusesMutation,
    useGetUserSuggestionsQuery,
    useGetJobKeywordSuggestionsQuery,
    useGetJobKeywordSuggestionsRestrictedQuery,
    useGetEmployerSuggestionsQuery,
    useGetEmployersByLocationQuery,
    useGetJobCreditsQuery,
    useDownloadInvoiceMutation,
    useUpdateEmployerMutation,
    useCreateEmployerMutation,
    useUpdateJobMutation,
    useUpdateUserMutation,
    useEditEmployerProfileMutation,
    useGetJobPaddingEmployersQuery,
    useGetMax5JobsEmployersQuery,
    useGetAcademicLeadJobsQuery,
    useShareJobAcademicLeadQuery,
    usePostAJobMutation,
    useAddOrderedProductsMutation,
    useDeductJobCreditsMutation,
    useGetOrderedProductsQuery,
    useTalentPoolEmailMutation,
    useSendEmail1Mutation,
    useSendEmail3Mutation,
    useSendEmailMutation,
    useShareJobMutation,
    useGetFiltersQuery,
    useGetJobByIdQuery,
    useGetOrderByIdQuery,
    useGetSingleQAQuery,
    useGetEmployersQuery,
    useGetJobsByEmployerQuery,
    useCreateInvoiceMutation,
    useGetJobsQuery,
    useGetJobs2Query,
    useGetEmployerQuery,
    useGetQtyQuery,
    useGetQty1Query,
    useGetAUNZEmployersQuery,
    useGetAUNZEmployersSalesLeadsQuery,
    useGetMainSecondContactsQuery,
    useGetMainSecondOtherContactsQuery,
    useSendClientAdminRequestEmailMutation,
    useGetJobBoardsQuery,
    useCreateJobBoardMutation,
    useUpdateJobBoardMutation,
    useDeleteJobBoardMutation,
} = apiSlice