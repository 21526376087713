import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ element: Component, allowedUserTypes, ...rest }) => {
  const userInfo = useSelector((state) => state.auth.userInfo); // Get userInfo from state

  return allowedUserTypes.includes(userInfo?.userType) ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/not-authorized" replace />
  );
};

export default ProtectedRoute;
