import React, { useState, useEffect } from "react";
import { useAddSalesNotesMutation } from "../store/apiSlice";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

const AddSalesNotes = ({ id }) => {
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [AddSalesNotes1] = useAddSalesNotesMutation();
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        resetField,
    } = useForm();

    const createTaskChecked = watch("createTask");

    useEffect(() => {
        if (createTaskChecked && userInfo) {
            setValue("actionBy", userInfo.id);
        } else {
            setValue("actionBy", null);
            setValue("actionDate", null);
        }
    }, [createTaskChecked, userInfo, setValue]);

    const onSubmit = async (data) => {
        if (data) AddSalesNotes1({ ...data, employer_id: id, writtenBy: `${userInfo.firstName} ${userInfo.lastName}` });
    };

    return (
        <div className="overflow-y w-full mb-6 p-4">
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
                <textarea
                    id="description-input"
                    name="07_Nominee_Description"
                    className="w-full px-4 py-3 border border-black rounded-lg text-black focus:outline-none focus:border-orange-500"
                    placeholder="Please write sales notes here"
                    {...register("note")}
                />

                <div className="flex items-center gap-2">
                    <input
                        type="checkbox"
                        id="create-task-checkbox"
                        {...register("createTask")}
                    />
                    <label htmlFor="create-task-checkbox">Create Task</label>
                </div>

                {createTaskChecked && (
                    <div className="w-full flex gap-2 justify-between text-left font-bold">
                        <div className="flex flex-start gap-1">
                            <label className="label-text text-xs mt-1">Action Assignee:</label>
                            <select className="text-xs" {...register("actionBy")}>
                                <option value="">Select an Agent</option>
                                {[{ name: 'Grace', id: '105' }, { name: 'Riley', id: '106' }].map(({ name, id }, i) => (
                                    <option key={i} value={id}>
                                        {name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-start gap-1">
                            <label className="label-text text-xs mt-1">Action Type:</label>
                            <select className="text-xs" {...register("actionType")}>
                                <option value="">Select an action type</option>
                                {['Call', 'Email', 'Meeting'].map((type, i) => (
                                    <option key={i} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-start gap-1">
                            <label className="label-text text-xs">Action Date:</label>
                            <input
                                type="date"
                                className="text-center md:text-left border border-[#00aeef] text-gray-500 rounded-md focus:ring-orange-500 focus:border-orange-500"
                                {...register("actionDate")}
                            />
                        </div>
                    </div>
                )}

                <button className="w-1/2 btn items-center m-auto">Add New Sales Notes</button>
            </form>
        </div>
    );
};

export default AddSalesNotes;
