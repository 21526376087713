// src/DashBoard.js
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetJobs3Query } from "../store/apiSlice";
import CountUp from 'react-countup';
import { Link, useLocation } from "react-router-dom";

const DashBoard = () => {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [q] = useState({ userId: -1, employer_id: userInfo?.employer_id || -1 });
  const { data, error, isLoading } = useGetJobs3Query({ ...q });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-xl font-semibold">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-center">
          <p className="text-lg">No job data to load, start posting now <a className="text-amber-500 hover:text-gray-500 underline" href="/post-job-employer">here</a>!</p>
        </div>
      </div>
    );
  }
  console.log("daaata", data)
  const totalJobs = data?.length || 0;
  const totalHeadlineJobs = data?.filter(job => job.headlineOnly === 1).length || 0;
  const totalFeaturedJobs = data?.filter(job => job.featured === 1).length || 0;

  return (
    <div className="px-4">
      <h2 className="text-gray-500 font-bold text-4xl mt-8">Job Stats</h2>
      <div className="grid grid-cols-1 gap-6 mt-8 sm:grid-cols-3">
        <Card title="Total Jobs" count={totalJobs} />
        <Card title="Featured Jobs" count={totalFeaturedJobs} />
        <Card title="Headline Jobs" count={totalHeadlineJobs} />
      </div>
      <div className="flex justify-center mt-8">
      <Link
          to={"/post-job-employer"}
          role="alert"
          className={`btn btn-aj`}
        >
          <button>Post a Job now</button>
        </Link>
      </div>

      <div className="mt-20">
        <h2 className="text-gray-500 font-bold text-4xl mb-8">Our Audience</h2>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
          <InfoCard
            title="Fastest Growing Job Board on Google"
            description="Purpose built for google optimization for Teaching Jobs in Australia"
            imgSrc="./employerDashboard/google.png"
            borderColor={"border-[#FF828A]"}
            width={200}
            height={100}
            textSize={"text-md"}

          />

          <div className="border-4 border-[#FF76B8] rounded-xl p-6 text-center shadow-lg">
            <div className="flex justify-center">
              <img src="./employerDashboard/numberOne.png" alt="alt" className="mx-auto" width={200} height={100} />
            </div>
            <h3 className="text-2xl text-black font-semibold mb-4">For Schools</h3>
            <ul className="grid grid-cols-2 gap-4 text-gray-700 text-md">
              <li className="flex items-center justify-center bg-[#e74b7f] text-white font-bold p-2 rounded-lg shadow-md">Rural</li>
              <li className="flex items-center justify-center bg-[#e74b7f] text-white font-bold p-2 rounded-lg shadow-md">Religious</li>
              <li className="flex items-center justify-center bg-[#e74b7f] text-white font-bold p-2 rounded-lg shadow-md">Private</li>
              <li className="flex items-center justify-center bg-[#e74b7f] text-white font-bold p-2 rounded-lg shadow-md">Public</li>
            </ul>
          </div>


          <InfoCardSocials
            title="Social Reach"
            description="1.2 million +"
            imgSrc1="./employerDashboard/insta.png"
            imgSrc2="./employerDashboard/facebook.png"
            imgSrc3="./employerDashboard/linkedin.png"
            imgSrc4="./employerDashboard/tiktok.png"

            borderColor={"border-[#FF828A]"}
            width={100}
            height={50}
            textSize={"text-6xl"}
          />
        </div>
        <div className="grid grid-cols-1 gap-6 mt-8 sm:grid-cols-3">
          <StatCard title="Average job views for featured jobs" stat="98" borderColor={"border-[#FF828A]"} />
          <StatCard title="Average apply now hits for featured jobs" stat="13" borderColor={"border-[#FF76B8]"} />
          <StatCard title="Monthly job alerts" stat="33,000+" borderColor={"border-[#FF828A]"} />
        </div>
      </div>
    </div>
  );
};

const Card = ({ title, count }) => {
  const [firstWord, secondWord] = title.split(' ');

  return (
    <div className="border border-gray-300 rounded-lg p-6 text-center shadow-lg">
      <h2 className="text-2xl font-semibold mb-4">
        <span className="block">{firstWord}</span>
        <span className="block">{secondWord}</span>
      </h2>
      <p className="text-4xl font-bold">
        <CountUp end={count} duration={2.5} />
      </p>
    </div>
  );
};


const InfoCard = ({ title, description, imgSrc, secondImg, borderColor, width, height, textSize }) => (
  <div className={`border-4 ${borderColor} rounded-xl p-6 text-center shadow-lg`}>
    <h3 className="text-2xl text-black font-semibold">{title}</h3>
    <div className="flex justify-center">
      <img src={imgSrc} alt={title} className="mx-auto" width={width} height={height} />
    </div>
    <p className={`text-gray-700 ${textSize}`}>{description}</p>
    {secondImg && <img src={secondImg} alt={title} className="" />}
  </div>
);

const InfoCardSocials = ({ title, description, imgSrc1, imgSrc2, imgSrc3, imgSrc4, borderColor, width, height, textSize }) => (
  <div className={`border-4 ${borderColor} rounded-xl p-6 text-center shadow-lg`}>
    <h3 className="text-2xl text-black font-semibold mb-4">{title}</h3>
    <div className="grid grid-cols-2 gap-1 mb-4">
      <img src={imgSrc1} alt={`${title} 1`} className="mx-auto" width={width} height={height} />
      <img src={imgSrc2} alt={`${title} 2`} className="mx-auto" width={width} height={height} />
      <img src={imgSrc3} alt={`${title} 3`} className="mx-auto" width={width} height={height} />
      <img src={imgSrc4} alt={`${title} 4`} className="mx-auto" width={width} height={height} />
    </div>
    <p className={`text-gray-700 ${textSize}`}>{description}</p>
  </div>
);


const StatCard = ({ title, stat, subtext, borderColor }) => (
  <div className={`border-2 ${borderColor} rounded-xl p-6 text-center shadow-lg`}>
    <h3 className="text-xl text-black font-semibold mb-2">{title}</h3>
    <p className="text-5xl font-bold mb-2">{stat}</p>
    {subtext && <p className="text-sm text-gray-700">{subtext}</p>}
  </div>
);

export default DashBoard;
