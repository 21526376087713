import React, { useRef } from "react";
import { Link } from "react-router-dom";
import JobKeywordSearchBlock from "./JobKeywordSearchBlock";
export default function ASPAComponent() {
  const jobsByState = [
    {
      name: `ACT Principal & School Leader Jobs`,
      heading: `Australian Capital Territory School Leader Jobs`,
      link: `https://www.teachingjobs.com.au/act`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Teaching%20Jobs%20Australia%20ACT%20school%20dept%20education%20careers.jpg`,
    },
    {
      name: `NSW Principal & School Leader Jobs`,
      heading: `New South Wales School Leader Jobs`,
      link: `https://www.teachingjobs.com.au/jobs?r=Australia&l=&q=&lon=0&lat=0&page=0&category=&currentMiddleCategory=&filter0=[{"category":"State","filter":"New South Wales"},{"category":"SchoolAffiliation","filter":"Public"},{"category":"JobType","filter":"Leadership"}]&mode=default
`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/NSW_Logo.jpg`,
    },
    {
      name: `NT Principal & School Leader Jobs`,
      heading: `Northern Territory School Leader Jobs`,
      link: `https://www.teachingjobs.com.au/northern-territory`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/logo_4.jpg`,
    },
    {
      name: `Qld Principal & School Leader Jobs`,
      heading: `Queensland School Leader Jobs`,
      link: `https://www.teachingjobs.com.au/queensland`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/queensland1.jpg`,
    },
    {
      name: `SA Principal & School Leader Jobs`,
      heading: `South Australia School Leader Jobs`,
      link: `https://www.teachingjobs.com.au/south-australia`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/sa_logo.png`,
    },
    {
      name: `Tas Principal & School Leader Jobs`,
      heading: `Tasmania School Leader Jobs`,
      link: `https://www.teachingjobs.com.au/tasmania`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/TAS_Logo.png`,
    },
    {
      name: `Vic Principal & School Leader Jobs`,
      heading: `Victoria School Leader Jobs`,
      link: `https://www.teachingjobs.com.au/victoria`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/Victorian%20public%20school%20jobs.png`,
    },
    {
      name: `WA Principal & School Leader Jobs`,
      heading: `Western Australia School Leader Jobs`,
      link: `https://www.teachingjobs.com.au/western-australia`,
      logo_src: `https://academicjobs.s3.amazonaws.com/img/university-logo/WA_Logo.png`,
    },
  ];
  const profileShotLinks = [
    {
      src: "https://www.teachingjobs.com.au/home/teacher2.jpeg",
      alt: "Early Childhood Teachers",
      label: "Early Childhood",
      link: `https://www.teachingjobs.com.au/early-childhood-jobs?r=Australia&filter0=[{"category":"subject","filter":"Early learning/Early childhood/Childcare"}]`,
    },
    {
      src: "https://www.teachingjobs.com.au/home/academic-student-positions.png",
      alt: "Primary School Positions",
      label: "Primary",
      link: `https://www.teachingjobs.com.au/primary-jobs?r=Australia&filter0=[{"category":"schoolType","filter":"Primary"}]`,
    },
    {
      src: "https://www.teachingjobs.com.au/home/teacher3.jpeg",
      alt: "Secondary School Jobs",
      label: "Secondary",
      link: `https://www.teachingjobs.com.au/secondary-jobs?r=Australia&filter0=[{"category":"schoolType","filter":"Secondary"}]`,
    },
    {
      src: "https://www.teachingjobs.com.au/home/teacher-4.jpg",
      alt: "Teacher Aide Jobs",
      label: "Teacher Aide",
      link: `https://www.teachingjobs.com.au/teacher-aide-jobs?r=Australia&filter0=[{"category":"subcategory_academic_jobs","filter":"Teaching Assistant"}]`,
    },
    {
      src: "https://www.teachingjobs.com.au/home/teacher1.jpeg",
      alt: "Administrative Positions",
      label: "Admin",
      link: `https://www.teachingjobs.com.au/admin-jobs?r=Australia&filter0=[{"category":"subcategory_academic_jobs","filter":"Administrator/Clerk"}]`,
    },
  ];
  const subjectLinks = [
    {
      name: "Science",
      href: "https://www.teachingjobs.com.au/science-teacher-jobs",
      isPrimary: true,
    },
    {
      name: "Maths",
      href: "https://www.teachingjobs.com.au/maths-teacher-jobs",
      isPrimary: true,
    },
    {
      name: "English",
      href: "https://www.teachingjobs.com.au/english-teacher-jobs",
      isPrimary: true,
    },
    {
      name: "Art",
      href: "https://www.teachingjobs.com.au/art-teacher-jobs",
      isPrimary: true,
    },
    {
      name: "Music",
      href: "https://www.teachingjobs.com.au/music-teacher-jobs",
      isPrimary: true,
    },
    {
      name: "History",
      href: "https://www.teachingjobs.com.au/history-teacher-jobs",
      isPrimary: true,
    },
    {
      name: "Physical Education",
      href: "https://www.teachingjobs.com.au/pe-teacher-jobs",
      isPrimary: true,
    },
  ];
  const schoolTypeLinks = [
    {
      name: "Public",
      href: `https://www.teachingjobs.com.au/jobs?r=Global&filter0=[{"category":"SchoolAffiliation","filter":"Public"}]`,
      isPrimary: true,
    },
    {
      name: "Private",
      href: `https://www.teachingjobs.com.au/jobs?r=Global&filter0=[{"category":"SchoolAffiliation","filter":"Private"}]`,
      isPrimary: true,
    },
    {
      name: "Catholic",
      href: `https://www.teachingjobs.com.au/jobs?r=Global&filter0=[{"category":"SchoolAffiliation","filter":"Catholic"}]`,
      isPrimary: true,
    },
    {
      name: "Independent",
      href: `https://www.teachingjobs.com.au/jobs?r=Global&filter0=[{"category":"SchoolAffiliation","filter":"Independent"}]`,
      isPrimary: true,
    },
  ];

  let note = "";

  if (profileShotLinks)
    note = "Not needed for this version but want to keep for next partnership!";
  if (subjectLinks)
    note = "Not needed for this version but want to keep for next partnership!";
  if (schoolTypeLinks)
    note = "Not needed for this version but want to keep for next partnership!";

  const keyWordRef = useRef(null);
  const cfRef = useRef(null);
  const linkRef = useRef(null);
  const onEditorStateSelect = (category, filter) => {
    console.log(category, filter);

    if (filter === "All Indigenous Jobs in Australia") {
      keyWordRef.current = "Indigenous";
      return;
    }

    linkRef.current = null;
    cfRef.current = { category, filter };
    console.log(category, filter);
  };
  const onInputChange = (inputText) => {
    keyWordRef.current = inputText;
  };
  //alert(country)
  //alert(countryMap[country])
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (linkRef.current) {
      const url = linkRef.current;
      //alert(url); // Just for debugging purposes
      window.open(url, "_blank");
      return;
    }
    let q3 = "";
    if (cfRef.current) {
      q3 = `&filter0=[{"category":"JobType","filter":"${cfRef.current.category}"},`;
      q3 += `{"category":"LeadershipJobs","filter":"${cfRef.current.filter}"},`;
      q3 += `{"category":"SchoolAffiliation","filter":"Public"},`;
      q3 += `{"category":"SchoolType","filter":"Secondary"}]`;
    } else {
      if (keyWordRef?.current?.trim()) {
        q3 = `&q=${keyWordRef?.current?.trim()}`;
      }
    }
    const url = `https://www.teachingjobs.com.au/jobs?r=Australia${q3}`;
    //alert(url); // Just for debugging purposes
    window.open(url, "_blank");
  };

  // const size = 150;

  return (
    <div className="aspa_theme flex flex-col gap-4 Xjustify-center items-center Xmin-h-screen py-10 px-4 mb-16">
      <form
        className="flex flex-col gap-1 items-center w-[50%]"
        onSubmit={handleFormSubmit}
      >
        <div className="bg-white relative flex flex-col md:flex-row md:gap-2 mx-18 w-full  md:border rounded-t-lg md:rounded-lg p-2 md:shadow-md">
          <div className="relative z-50 flex-grow flex items-center">
            {/* Ensure the container is flex and items-center */}
            <JobKeywordSearchBlock
              forceClass=""
              onSelect={onEditorStateSelect}
              onInputChange={onInputChange}
              className="w-full"
              label={
                "Enter role, select it from the drop-down list & click Search"
              }
              category1="Leadership"
            />
          </div>
          <button
            className="h-[50px] ml-auto px-4 py-2 bg-[#003650] w-full md:w-auto text-white rounded-md rounded-b-lg hover:bg-[#ff009d] animate-pulse hover:animate-none font-bold shadow-md"
            type="submit"
          >
            Search
          </button>
        </div>
      </form>

      {/* <ul className="hero-icons hide flex gap-2 md:gap-5 mx-auto text-center items-center justify-between md:justify-center p-4 z-20">
        {profileShotLinks.map((item, index) => (
          <li key={index}>
            <Link target="_blank" rel="noopener" to={item.link}>
              <img
                className="grayscale hover:grayscale-0"
                src={item.src}
                width={size}
                height={size}
                alt={item.alt}
              />
            </Link>
            <Link className="mb-4" href={item.link}>
              <p>{item.label}</p>
            </Link>
          </li>
        ))}
      </ul> */}

      {/* Capital Cities */}
      <ul className="mb-4 text-center justify-center flex gap-4  mt-8 h-auto w-full flex-wrap">
        <li className="pb-2 flex hover:underline ">
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.teachingjobs.com.au/melbourne"
          >
            Melbourne
          </a>
        </li>
        <li className="pb-2 flex  hover:underline">
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.teachingjobs.com.au/sydney"
          >
            Sydney
          </a>
        </li>
        <li className="pb-2 flex md:break hover:underline">
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.teachingjobs.com.au/brisbane"
          >
            Brisbane
          </a>
        </li>
        <li className="pb-2 flex md:break hover:underline">
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.teachingjobs.com.au/gold-coast"
          >
            Gold Coast
          </a>
        </li>

        <li className="pb-2 flex  hover:underline">
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.teachingjobs.com.au/canberra"
          >
            Canberra
          </a>
        </li>
        <li className="pb-2 flex md:break hover:underline">
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.teachingjobs.com.au/perth"
          >
            Perth
          </a>
        </li>
        <li className="pb-2 flex md:break hover:underline">
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.teachingjobs.com.au/adelaide"
          >
            Adelaide
          </a>
        </li>
        <li className="pb-2 flex md:break hover:underline">
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.teachingjobs.com.au/darwin"
          >
            Darwin
          </a>
        </li>
      </ul>

      {/* <ul className="faculty-container flex gap-4 items-center justify-center text-[#e74b7f] mx-2 flex-wrap">
        {subjectLinks.map((link) => (
          <>
            {link.isPrimary ? (
              <li
                key={link.name}
                className="whitespace-nowrap hover:underline hover:text-pink-700"
              >
                <Link target="_blank" rel="noopener" to={link.href.toString()}>
                  {link.name}
                </Link>
              </li>
            ) : null}
          </>
        ))}
      </ul> */}
      {/* <ul className="faculty-container flex gap-4 items-center justify-center text-[#e74b7f] mx-2 flex-wrap">
        {schoolTypeLinks.map((link) => (
          <>
            {link.isPrimary ? (
              <li
                key={link.name}
                className="whitespace-nowrap hover:underline hover:text-pink-700"
              >
                <Link target="_blank" rel="noopener" to={link.href}>
                  {link.name}
                </Link>
              </li>
            ) : null}
          </>
        ))}
      </ul> */}

      {/* Main content */}
      <div className="flex flex-col-reverse sm:flex-row justify-between gap-16 mt-8 w-full max-w-[1400px]">
        <aside className="sm:min-w-[410px]">
          <h2 className="pt-0 pb-5 text-2xl md:text-3xl ">
            Search Principal and School Leader Jobs
          </h2>
          <p className="mb-6 font-bold">
            Click on an item below to see jobs available in that state\
          </p>
          <ul className="w-full remove_bullet grid grid-cols-1 lg:grid-cols-2 gap-10">
            {jobsByState.map((state, index) => (
              <li key={index} className="m-0 p-0 rounded-3xl cursor-pointer">
                <Link target="_blank" rel="noopener" to={state.link}>
                  <div
                    class="flip-card h-[220px] sm:h-[240px] rounded-3xl"
                    tabIndex="0"
                  >
                    <div class="flip-card-inner rounded-3xl">
                      <div class="flip-card-front rounded-3xl p-4 flex flex-col justify-between items-center gap-4">
                        <div
                          className="flex justify-center items-center p-0 m-0 max-w-[100px] min-h-[140px]"
                          // style={{ maxHeight: "20px", maxWidth: "20px" }}
                        >
                          <img
                            src={state.logo_src}
                            alt={"State Government Logo"}
                            className=" p-0 m-0 w-auto h-auto"
                          />
                        </div>
                        <h3 className="m-0 p-0 text-md font-bold leading-tight">
                          {state.heading}
                        </h3>
                      </div>
                      <div class="flip-card-back rounded-3xl p-4">
                        <h3 className="uni_name m-0 p-0 text-2xl font-bold">
                          {state.name}
                        </h3>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </aside>
        <section className="w-full max-w-[740px] bg-pink-300">
          Job Results
        </section>
      </div>

      <h3 className="mx-auto max-w-[800px] mt-10 text-2xl">
        <strong>Benefits to Schools:</strong>
      </h3>
      <ul className="list-disc list-outside ml-4 mb-16">
        <li>
          Increasing job advertising reach, reducing advertising costs, and
          minimising administration time for recruitment
        </li>
        <li>
          The easiest and most efficient way for job seekers to find and compare
          all teaching and school staff positions across Australia
        </li>
        <li>
          Continuously improved through collaboration with schools nationwide
        </li>
        <li>Find the perfect candidate for your school today!</li>
      </ul>
    </div>
  );
}
