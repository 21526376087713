import { Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link, NavLink } from "react-router-dom";
import { useSendLogoutMutation } from "../views/auth/authApiSlice";
import DashboardSection from './DashboardSection';
import JobPostCredits from './JobPostCredits';
import { useGetQty1Query } from '../store/apiSlice'
import { setEmployer } from '../store/postsSlice'

const Layout1 = () => {
  const [sendLogout] = useSendLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const region = useSelector((state) => state.posts.region);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const handleClick = async () => {
    await dispatch(setEmployer({ employer_id: 38 })); // Ensure employer is set
    navigate(`/employers-center/38/`); // Navigate after setting the employer
  };
  const {
    data: qty,
    isLoading: isLoadingQty,
    isSuccess: isSuccessQty,
  } = useGetQty1Query({
    userId: -1,
    employer_id: 38,
  })
  let content
  if (userInfo.employer_id > 0) {
    content = (
      <div className={`w-full min-h-screen`}>
        <div className="custom-background full-width relative">
          <div className="container md:pb-32 flex flex-col lg:flex-row items-center justify-between lg:min-h-[300px]">
            <a href="/dashboard" className="flex-shrink-0">
              <img src="/NewTeachingJobsLogo.png" alt="academic jobs" width="250" height="250" className="md:block hidden cursor-pointer" />
              <img src="/NewTeachingJobsLogo.png" alt="academic jobs" width="180" height="180" className="md:hidden block cursor-pointer mt-2" />
            </a>

            <div className="hidden md:block z-10">
              <span
                className=" text-white cursor-pointer whitespace-nowrap"
                onClick={() => {
                  sendLogout();
                  navigate("/");
                  window.location.reload();
                }}
              >
                <svg className="w-8 text-white mr-1 inline-block cursor-pointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M10 2v2H4v16h6v2H2V2h8zm11.707 10.707l-5-5-1.414 1.414L18.586 11H8v2h10.586l-3.293 3.293 1.414 1.414 5-5z" />
                </svg>
                Logout              </span>
            </div>
          </div>

          <div className="custom-shape-divider-bottom full-width relative z-5">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
              <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
              <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
            </svg>
          </div>
        </div>

        {/* <Profile
          id={userInfo.id}
          firstName={userInfo.firstName}
          lastName={userInfo.lastName}
          username={userInfo.username}
          profileStrength={30}
          location={location}
          rank={rank}
          organization={userInfo.orgnization_name}
          avatar={
          userInfo.portrait
              ? `https://academicjobs.s3.amazonaws.com/img/users/${userInfo.portrait}`
              : "/user.jpg"
          }
          wavesOn={true}
          hidden={true}
        /> */}
        <div className="content-grid mb-16">
          {/* <h2 className="profile mb-8">Hi {userInfo.firstName}</h2> */}
          <section
            title="Organisation Details"
            className="flex gap-8 justify-between relative"
          >
            {/* Employer logo */}
            <div className="flex">
              <img
                href="/admin-home/"
                src={`${userInfo.logo
                  ? `https://academicjobs.s3.amazonaws.com/img/university-logo/${userInfo.logo}`
                  : "/favicon.png"
                  }`}
                alt=""
                className="md:h-32 md:w-32 h-24 w-24"
              />
              <div>
                <h2 className="uni_name p-0 m-0 ml-6 leading-tight">
                  {userInfo.orgnization_name}
                </h2>
                <div className="flex ml-6 ">
                  {/* <StarRank
                    ranking={userInfo.ranking}
                    forceclassName="justify-center"
                  />
                  <ToolTip
                    title="Employer Ranking"
                    message="This is a tooltip message"
                    cta="Contact Me"
                    ctaLink="mailto:sarah@academicjobs.com"
                  >
                    <p>
                      Hi, I'm Sarah, please{" "}
                      <a
                        className="underline text-amber-500 hover:text-amber-700"
                        href="mailto:sarah@academicjobs.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        email me
                      </a>{" "}
                      and ask me about your employer ranking and strategies to
                      improve it.
                    </p>
                    <img
                      src="https://www.academicjobs.com/staff/academic-rankings-chair-sarah-west.png"
                      alt="Sarah West"
                      style={{
                        width: 100,
                        height: "auto",
                        borderRadius: "50%",
                        marginRight: 10,
                        marginTop: 20,
                      }}
                    />
                    <p className="mt-4 mb-0">
                      <strong>Sarah West</strong>
                    </p>
                    <p className="mb-0">Your Employer Ranking Specialist</p>
                    <p className="mb-0">m: +61 (0) 452 070 156</p>
                    <p className="mb-0">e: sarah@academicjobs.com</p>
                  </ToolTip> */}
                </div>
              </div>
            </div>
            {/* Employer Name and Rank */}

            {/* Employer Stats */}
            <div className="md:block hidden">
              <JobPostCredits employerDetails={userInfo} />
            </div>
          </section>
          <div className="md:hidden block mt-2 ">
            <JobPostCredits employerDetails={userInfo} />
          </div>

          <DashboardSection userInfo={userInfo} />
          <section title="Dashboard Area" className="profile_grid" style={{ marginTop: '0px', marginLeft: '0px' }}>
            <aside className="side_nav hidden">
              <ul className="menu dashboard_menu bg-slate-100 rounded-box">
                <li title="Dashboard">
                  <Link to={`/admin-home/`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-8 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                      />
                    </svg>
                    Dashboard
                  </Link>
                </li>
                <li title="My Profile">
                  <Link to={`/employers-center/${userInfo.employer_id}`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      className="svg-icon h-8 w-5"
                      viewBox="0 0 36 36"
                    >
                      <path
                        fill="currentColor"
                        d="M18 17a7 7 0 1 0-7-7a7 7 0 0 0 7 7m0-12a5 5 0 1 1-5 5a5 5 0 0 1 5-5"
                        className="clr-i-outline clr-i-outline-path-1"
                      />
                      <path
                        fill="currentColor"
                        d="M30.47 24.37a17.16 17.16 0 0 0-24.93 0A2 2 0 0 0 5 25.74V31a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2v-5.26a2 2 0 0 0-.53-1.37M29 31H7v-5.27a15.17 15.17 0 0 1 22 0Z"
                        className="clr-i-outline clr-i-outline-path-2"
                      />
                      <path fill="none" d="M0 0h36v36H0z" />
                    </svg>
                    My Profile
                  </Link>
                </li>
                <li title="Organisation Profile">
                  <Link to={`/employers-center/${userInfo.employer_id}`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      className="svg-icon h-8 w-5"
                      viewBox="0 0 36 36"
                    >
                      <path
                        fill="currentColor"
                        d="M18 17a7 7 0 1 0-7-7a7 7 0 0 0 7 7m0-12a5 5 0 1 1-5 5a5 5 0 0 1 5-5"
                        className="clr-i-outline clr-i-outline-path-1"
                      />
                      <path
                        fill="currentColor"
                        d="M30.47 24.37a17.16 17.16 0 0 0-24.93 0A2 2 0 0 0 5 25.74V31a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2v-5.26a2 2 0 0 0-.53-1.37M29 31H7v-5.27a15.17 15.17 0 0 1 22 0Z"
                        className="clr-i-outline clr-i-outline-path-2"
                      />
                      <path fill="none" d="M0 0h36v36H0z" />
                    </svg>
                    Organisation Profile
                  </Link>
                </li>
                <li title="Jobs">
                  <Link to={`/jobs-center1`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="32"
                      viewBox="0 0 24 24"
                      className="svg-icon h-6 p-0"
                    >
                      <path
                        fill="currentColor"
                        d="M11 22v-4H6l-3-3l3-3h5v-2H4V4h7V2h2v2h5l3 3l-3 3h-5v2h7v6h-7v4zM6 8h11.175l1-1l-1-1H6zm.825 8H18v-2H6.825l-1 1zM6 8V6zm12 8v-2z"
                      />
                    </svg>
                    Jobs
                  </Link>
                </li>
                <li title="Post a Job">
                  <Link className="" to={`/post-job-employer`}>
                    <svg
                      className="h-8 w-5 py-2"
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M8 1a2 2 0 0 1 2 2v2H6V3a2 2 0 0 1 2-2zm3 4V3a3 3 0 1 0-6 0v2H3.36a1.5 1.5 0 0 0-1.483 1.277L.85 13.13A2.5 2.5 0 0 0 3.322 16h9.355a2.5 2.5 0 0 0 2.473-2.87l-1.028-6.853A1.5 1.5 0 0 0 12.64 5H11zm-1 1v1.5a.5.5 0 0 0 1 0V6h1.639a.5.5 0 0 1 .494.426l1.028 6.851A1.5 1.5 0 0 1 12.678 15H3.322a1.5 1.5 0 0 1-1.483-1.723l1.028-6.851A.5.5 0 0 1 3.36 6H5v1.5a.5.5 0 1 0 1 0V6h4z"></path>
                    </svg>
                    Post My Job
                  </Link>
                </li>
                <li title="My Ambassadors">
                  <Link className="" to={`/post-job-employer`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 36 36"
                      className="h-8 w-5 py-2"
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      height="1em"
                      width="1em"
                    >
                      <path
                        fill="#000000"
                        d="M17.9 17.3c2.7 0 4.8-2.2 4.8-4.9s-2.2-4.8-4.9-4.8S13 9.8 13 12.4c0 2.7 2.2 4.9 4.9 4.9m-.1-7.7c.1 0 .1 0 0 0c1.6 0 2.9 1.3 2.9 2.9s-1.3 2.8-2.9 2.8c-1.6 0-2.8-1.3-2.8-2.8c0-1.6 1.3-2.9 2.8-2.9"
                        className="clr-i-outline clr-i-outline-path-1"
                      />
                      <path
                        fill="#000000"
                        d="M32.7 16.7c-1.9-1.7-4.4-2.6-7-2.5h-.8c-.2.8-.5 1.5-.9 2.1c.6-.1 1.1-.1 1.7-.1c1.9-.1 3.8.5 5.3 1.6V25h2v-8z"
                        className="clr-i-outline clr-i-outline-path-2"
                      />
                      <path
                        fill="#000000"
                        d="M23.4 7.8c.5-1.2 1.9-1.8 3.2-1.3c1.2.5 1.8 1.9 1.3 3.2c-.4.9-1.3 1.5-2.2 1.5c-.2 0-.5 0-.7-.1c.1.5.1 1 .1 1.4v.6c.2 0 .4.1.6.1c2.5 0 4.5-2 4.5-4.4c0-2.5-2-4.5-4.4-4.5c-1.6 0-3 .8-3.8 2.2c.5.3 1 .7 1.4 1.3"
                        className="clr-i-outline clr-i-outline-path-3"
                      />
                      <path
                        fill="#000000"
                        d="M12 16.4c-.4-.6-.7-1.3-.9-2.1h-.8c-2.6-.1-5.1.8-7 2.4L3 17v8h2v-7.2c1.6-1.1 3.4-1.7 5.3-1.6c.6 0 1.2.1 1.7.2"
                        className="clr-i-outline clr-i-outline-path-4"
                      />
                      <path
                        fill="#000000"
                        d="M10.3 13.1c.2 0 .4 0 .6-.1v-.6c0-.5 0-1 .1-1.4c-.2.1-.5.1-.7.1c-1.3 0-2.4-1.1-2.4-2.4c0-1.3 1.1-2.4 2.4-2.4c1 0 1.9.6 2.3 1.5c.4-.5 1-1 1.5-1.4c-1.3-2.1-4-2.8-6.1-1.5c-2.1 1.3-2.8 4-1.5 6.1c.8 1.3 2.2 2.1 3.8 2.1"
                        className="clr-i-outline clr-i-outline-path-5"
                      />
                      <path
                        fill="#000000"
                        d="m26.1 22.7l-.2-.3c-2-2.2-4.8-3.5-7.8-3.4c-3-.1-5.9 1.2-7.9 3.4l-.2.3v7.6c0 .9.7 1.7 1.7 1.7h12.8c.9 0 1.7-.8 1.7-1.7v-7.6zm-2 7.3H12v-6.6c1.6-1.6 3.8-2.4 6.1-2.4c2.2-.1 4.4.8 6 2.4z"
                        className="clr-i-outline clr-i-outline-path-6"
                      />
                      <path fill="none" d="M0 0h36v36H0z" />
                    </svg>
                    My Ambassadors
                  </Link>
                </li>
              </ul>
            </aside>
            <div className="main_content">
              <Outlet />
            </div>
          </section>
        </div>
      </div>
    );
  } else {
    content = (
      <div className={`w-full min-h-screen flex`}>
        <div className="z-[1] menu relative w-57 h-auto flex-none bg-base-200 pt-4">
          <ul className="flex flex-col gap-4 text-lg  items-start "> {/* <h1 className="text-xl font-black md:py-4 md:px-4">Employer Center</h1> */}
            <Link
              to={`/admin-home/`}>
              <img
                className="w-60"
                src="/NewTeachingJobsLogo.png"
                alt=""
              />
            </Link>
            <img
              src={userInfo.portrait ? `https://academicjobs.s3.amazonaws.com/img/users/${userInfo.portrait}` : "/user.jpg"}
              width={150}
              height={150}
              className="lg:max-w-lg rounded-3xl shadow-2xl mb-8 lg:mb-0 items-center justify-center ml-10"
              alt="AI Powered Recruitment Platform"
            />
            <li className="text-lg font-bold text-left">
              <Link
                to={`/admin-home/`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  />
                </svg>
                Dashboard
              </Link>
            </li>
            <li className="text-lg font-bold text-left">
              <Link to={userInfo.employer_id ? `/employers-center/${userInfo.employer_id}` : `/employers`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  class="svg-icon h-8 w-5"
                  viewBox="0 0 36 36"
                >
                  <path
                    fill="currentColor"
                    d="M18 17a7 7 0 1 0-7-7a7 7 0 0 0 7 7m0-12a5 5 0 1 1-5 5a5 5 0 0 1 5-5"
                    class="clr-i-outline clr-i-outline-path-1"
                  />
                  <path
                    fill="currentColor"
                    d="M30.47 24.37a17.16 17.16 0 0 0-24.93 0A2 2 0 0 0 5 25.74V31a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2v-5.26a2 2 0 0 0-.53-1.37M29 31H7v-5.27a15.17 15.17 0 0 1 22 0Z"
                    class="clr-i-outline clr-i-outline-path-2"
                  />
                  <path fill="none" d="M0 0h36v36H0z" />
                </svg>
                Employers
              </Link>
            </li>
            <li className="text-lg font-bold text-left">
              <Link to={`/jobs-center`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="32"
                  viewBox="0 0 24 24"
                  class="svg-icon h-6 p-0"
                >
                  <path
                    fill="currentColor"
                    d="M11 22v-4H6l-3-3l3-3h5v-2H4V4h7V2h2v2h5l3 3l-3 3h-5v2h7v6h-7v4zM6 8h11.175l1-1l-1-1H6zm.825 8H18v-2H6.825l-1 1zM6 8V6zm12 8v-2z"
                  />
                </svg>
                Jobs
              </Link>
            </li>
            <li className="text-lg font-bold text-left ">
              <Link
                className=""
                to={`/post-a-job`}
              >
                <svg
                  className="h-8 w-5 py-2"
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 1a2 2 0 0 1 2 2v2H6V3a2 2 0 0 1 2-2zm3 4V3a3 3 0 1 0-6 0v2H3.36a1.5 1.5 0 0 0-1.483 1.277L.85 13.13A2.5 2.5 0 0 0 3.322 16h9.355a2.5 2.5 0 0 0 2.473-2.87l-1.028-6.853A1.5 1.5 0 0 0 12.64 5H11zm-1 1v1.5a.5.5 0 0 0 1 0V6h1.639a.5.5 0 0 1 .494.426l1.028 6.851A1.5 1.5 0 0 1 12.678 15H3.322a1.5 1.5 0 0 1-1.483-1.723l1.028-6.851A.5.5 0 0 1 3.36 6H5v1.5a.5.5 0 1 0 1 0V6h4z"></path>
                </svg>
                Post a Job
              </Link>
            </li>
            <li className="text-lg font-bold text-left ">
              <Link
                className=""
                to={`/UsersTask`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Task Allocation<br />(Management Only)
              </Link>
            </li>
            <li className="text-lg font-bold text-left ">
              <Link
                className=""
                to={`/AddEditUsers`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Users<br />(Management Only)
              </Link>
            </li>
            <li className="text-lg text-pink-400 font-bold text-left ">
              <span
                className=""
                onClick={handleClick}
                style={{ cursor: 'pointer' }} // Use span with onClick instead of Link for custom logic
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Sales Leads <br />
                To be processed: {`${qty?.currentQty}`}
              </span>
            </li>

            <li className="text-lg font-bold text-left pl-1 ">
              <span
                className="  whitespace-nowrap"
                onClick={() => {
                  sendLogout();
                  navigate("/");
                  window.location.reload();
                }}
              >
                <img src="/logout-svgrepo-com.svg" alt="Logout" /> Logout
              </span>
            </li>
            {/* <li className="text-lg font-bold text-left ">
              <Link
                className=""
                to={`/JobLoadingActivity`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Job Loading Activity
              </Link>
            </li> */}
          </ul>
        </div>
        <div className=" w-full ml-[2px] mt-2 px-6 py-6">
          <div className="">
            <Outlet />
          </div>
        </div>
      </div>
    )
  }
  return content
}
export default Layout1
