import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import EmployerSearchResults from "./EmployerSearchResults";
// import { useGetOrderedProductsQuery } from "../store/apiSlice";
import { useParams, useNavigate, Link, NavLink } from "react-router-dom";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { setJobCredits } from "../store/postsSlice";
import InputBlock2 from "./InputBlock2";
import SelectBlock2 from "./SelectBlock2";
import SelectBlock from "./SelectBlock";
import UniSearchBlock from "./UniSearchBlock";
import { useForm } from "react-hook-form";
import EditEmployerProfile1 from "./EditEmployerProfile1";
import { setJob, setId, setEmployer } from "../store/postsSlice";
import { BsCheckLg } from "react-icons/bs";

function JobsCenter(props) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const region = useSelector((state) => state.posts.region);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [q, setQ] = useState({
    userId: -1,
    employer_id: userInfo?.employer_id || -1,
  });


  console.log("q", q);
  // if (userInfo.employer_id) {
  //   setQ({ userId: userInfo.employer_id })
  // }
  // const { data, isLoading, isSuccess } = useGetOrderedProductsQuery({ id: userInfo.id });
  let content, content1;
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    setError,
  } = useForm();
  const onEditorStateChange1 = (company_name, id, Region, country) => {

    setValue("employer_name", company_name);
  };
  const onSubmit = async (data) => {
    console.log("data", data);
    setQ(data);
  };
  return (
    <div className="flex flex-col px-6 gap-8 mt-8">
   
      {/* <AppContextTitleAndQuote
        title="Our Jobs"
        quote={`"Past, Present and Future"`}
        employerId={userInfo.employer_id}
      /> */}

      {/* <form onSubmit={handleSubmit(onSubmit)} className="w-full  ">
        <div className="flex justify-start items-end gap-1">
          <InputBlock2
            className="font-bold"
            type="text"
            field="title"
            label="Title"
            register={register}
            errors={errors}
            forceClass="font-bold"
          />
          <UniSearchBlock
            register={register}
            field="employer_name"
            customKey="employer_name"
            label="employer_name"
            value1=""
            forceClass=""
            onChange={onEditorStateChange1}
          />
          <InputBlock2
            type="date"
            field="from_date"
            label="From Date"
            register={register}
            errors={errors}
            forceClass="font-bold text-xl"
          />
          <InputBlock2
            type="date"
            field="to_date"
            label="To Date"
            register={register}
            errors={errors}
            forceClass=" font-bold"
          />
          <button type="submit" className="btn btn-success">
            Search
          </button>
        </div>
      </form> */}
      {/* <p className="text-3xl font-black text-orange-600 shadow-xl px-2 pb-4">Our Jobs</p> */}

      <EmployerSearchResults q={q} />
    </div>
  );
}
export default JobsCenter;
