import React from 'react';
import { useGetEmployersByLocationQuery } from '../store/apiSlice';

const EmployerList = ({ suburb }) => {
  // Call the hook unconditionally, but pass an appropriate value (null or undefined) when suburb is not available
  const { data: employers = [], error, isLoading } = useGetEmployersByLocationQuery(suburb || '');

  // Update the hasContactInfo check to include employer.email and employer.phone
  const hasContactInfo = (employer) =>
    employer.phone1 || employer.phone2 || employer.email1 || employer.email2 || employer.email || employer.phone;

  return (
    <div>
      {isLoading && <p>Loading...</p>}
      <h2 className='underline'>{`Employers in ${suburb || 'Unknown Location'}`}</h2>
      {employers.length > 0 ? (
        <ul>
          {employers.filter(hasContactInfo).length > 0 ? (
            employers
              .filter(hasContactInfo)
              .map((employer) => (
                <li
                  key={employer.id}
                  className="mb-2 p-4 border border-gray-300 rounded"
                >
                  <strong>{employer.company_name}</strong> - {employer.suburb}, {employer.location}
                  <div className="contact-info">
                    {/* Add employer's general email and phone */}
                    {employer.email && <p><strong>General Email:</strong> {employer.email}</p>}
                    {employer.phone && <p><strong>General Phone:</strong> {employer.phone}</p>}
                    {employer.phone1 || employer.email1 ? (
                      <>
                        <p>
                          <strong>Contact 1:</strong>
                        </p>
                        <p>
                          {employer.firstname1} {employer.lastname1}
                        </p>
                        {employer.email1 && <p>Email: {employer.email1}</p>}
                        {employer.phone1 && <p>Phone: {employer.phone1}</p>}
                      </>
                    ) : null}

                    {employer.phone2 || employer.email2 ? (
                      <>
                        <p>
                          <strong>Contact 2:</strong>
                        </p>
                        <p>
                          {employer.firstname2} {employer.lastname2}
                        </p>
                        {employer.email2 && <p>Email: {employer.email2}</p>}
                        {employer.phone2 && <p>Phone: {employer.phone2}</p>}
                      </>
                    ) : null}


                  </div>
                </li>
              ))
          ) : (
            <p>No employers with contact information found</p>
          )}
        </ul>
      ) : (
        <p>No employers found</p>
      )}
    </div>
  );
};

export default EmployerList;
